import { IMFIATPInventoryDynamicEntity, IMFIATPInventoryEntity, IMFIATPInventoryStatusEntity, IMFICartLine } from '../actions/CoreProductDataServiceEntities.g';
import { IProductDetails } from '../modules/mfrm-cart/mfrm-cart';
import { getSetParcelInventoryData, getSetProductData, IParcelInventoryDetails, getSetCartProductPrice, ICartProductPrice, getSetCheckoutCurrentStep, getSetPaymentRadioName, getSetExpressType, getSetSelectedDeliverySlotZone, getSetTimeSlot } from './cart-utils';
import { ICartState } from '@msdyn365-commerce/global-state';
import MsDyn365, { IActionContext, IAny, ICoreContext, IGeneric } from '@msdyn365-commerce/core';
import { Address, AttributeValue, CartLine, CategoryPathResult, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { atpInventoryDoRetry, cartOperationDoRetry, getInventoryQuantityInternal, updateCartLineInternal } from './doRetryCallBack';
import Cookies from 'universal-cookie';
import mfiAtpInventoryAction, { MfiAtpInventoryInput } from '../data-actions/mfi-atp-inventory.action';
import dayjs from 'dayjs';
import { ICheckoutViewProps } from '../themes/mattressfirm/views/checkout.view';
import { IProductWithAttribute } from '../data-actions/get-product-attributes-cartlines';
import { parseBrandName } from './get-product-brand';
import { IBrandListData } from '../modules/mfrm-buybox/mfrm-buybox.view';
import { urlFallBackImage } from './fallbackImageUrl';
import { GetCategoryPathsInput, getProductsCategoryPathsAction } from '../actions/get-mfrm-products-category-paths.action';
import _getInventoryQuantity from './get-inventory-quantity';
import { eventFullStoryPriceMismatch } from './analytics/fullStoryTrack';
import { IUsaEpayErrorMessage } from '../themes/mattressfirm/custom-components/mfrm-guided-card';
import { getDeliveryService } from './getDeliveryService';
import { getModOfDelivery } from './getDeliveryMod';
import { INodeProps } from '@msdyn365-commerce-modules/utilities';
import { set } from 'mobx';
import { IMfrmAddress, IShippingAddress } from '../modules/mfrm-checkout-shipping-address';
import { GetRecycleFeeCheckoutInput } from '../actions/get-recycle-fee-checkout.action';
import { DeliveryModes, MFRMProductTypes, coreItemShippingType } from './enum';
import { IErrorCaseForDeliveryDate } from '../modules/mfrm-checkout-delivery-options';
import { getDeliveryMessageForProduct } from '../modules/mfrm-checkout-delivery-options/utils/mfrm-delivery-option-utils';
import { CheckoutSkeletonInput } from '../data-actions/checkout-skeleton.action';
import { IMfrmCheckoutShippingAddressData } from '../modules/mfrm-checkout-shipping-address';
import { IMfrmCheckoutDeliveryOptionsData } from '../modules/mfrm-checkout-delivery-options';
import { IBasketCookie, getSetBasketCookie } from '../modules/mfrm-cart/utils/cart-utils';
import { IDeliveryProps } from '../modules/mfrm-cart/mfrm-cart.view';
import { getCookie } from '../common/cookies/cookie-utils';
import { ProgressiveDeleteTransactionAction, ProgressiveDeleteTransactionInput } from '../actions/progressive-actions/progressive-delete-transaction.action';
import { callUtagLink } from './analytics/utagCaller';

export interface IUpdatedCartDetails {
    cartState: ICartState;
    updatedCartLines: CartLine[] | undefined
}
export interface IDetailsCategoryWithDetails {
    productsCategories: CategoryPathResult[],
    newProductDetails: IProductDetails[],
}
export interface IAtpSlotsDetails {
    selectedSlotNotExist?: boolean;
    isNotAvailable?: boolean;
    isbackOrdered?: boolean;
}

export interface IProgressivePaymentInfo {
    InitialPayment?: number;
    TotalTax?: number;
    LeaseToOwnCost?: number;
    Term?: string;
    TotalMonthLeaseToOwnCost?: number;
}

export const _isAllSmallParcel = (): boolean => {
    let isAllParcel = true;
    const productspecificationsDetails: IProductDetails[] = getSetProductData('get');
    const { core, dropShip } = MFRMProductTypes;

    productspecificationsDetails?.forEach(product => {
        const productType = product.typeOfProduct?.toLowerCase();
        if (productType === core || productType === dropShip) {
            isAllParcel = false;
        }
    });
    return isAllParcel;
};

export const validateZipCode = (zipcode?: string | undefined) => {
    return zipcode && /^\d{5}$/.test(zipcode) && zipcode !== '00000';
};

export const _isExpressFlow = (context: ICoreContext<IGeneric<IAny>>) => {
    const currentUrl = MsDyn365.isBrowser && window.location.pathname?.substring(1);
    const applepayExpressUrl: string = context.app.config.expressApplePayCheckouturl || 'applepay-express';
    const paypalExpressUrl: string = context.app.expressCheckouturl || 'paypal-express';
    return paypalExpressUrl === currentUrl || applepayExpressUrl === currentUrl ? true : false;
};

export const _isAllNonCoreItems = (): boolean => {
    let isAllParcel = true;
    const productspecificationsDetails: IProductDetails[] = getSetProductData('get');

    productspecificationsDetails?.forEach(product => {
        const productType = product.typeOfProduct?.toLowerCase();
        if (productType === MFRMProductTypes.core) {
            isAllParcel = false;
        }
    });
    return isAllParcel;
};

// Update CartLines on Saving customer info with new location OR If customer saved shipping info or there is an error on checkout redirecting to payment step again, call updateCartlines to add warehouse details for tax calculation
// datePicker indicates if this function is called on changing date manually by User
export const _updateCartLinesWithWarehouseId = async (
    actionContext: IActionContext,
    checkoutCartState: ICartState,
    dynamicInventoryData: IMFIATPInventoryDynamicEntity[],
    selectedTimeSlot: string,
    dropShipDefaultWarehouseId: string,
    datePicker?: boolean,
    _getUpdatedCart?: boolean // To updateAttribute in cartlines, there is already UpdateCartLines api call so we'll just get UpdatedCart from here to use it in payload to update attribute and warehouse ID simultaneously
): Promise<IUpdatedCartDetails | undefined> => {
    const cartState = checkoutCartState;
    const currentCartLines = cartState?.cart.CartLines;
    const { core, smallParcel, dropShip } = MFRMProductTypes;
    // Get warehouseId for CORE from selected slot data

    let selectedAtpSlot: IMFIATPInventoryEntity | undefined;
    if (dynamicInventoryData && dynamicInventoryData.length > 0) {
        selectedAtpSlot = dynamicInventoryData[0].ATPSlots?.find(atpSlot => {
            const startAndEndTimeOfSlot = `${atpSlot.StartTime} - ${atpSlot.EndTime}`;
            return selectedTimeSlot === startAndEndTimeOfSlot?.toLowerCase()?.trim();
        });
    }
    const coreProductWarehouseId = selectedAtpSlot?.Location1 || '';

    const productspecificationsDetails: IProductDetails[] = getSetProductData('get');

    // Case datePicker: Check if new date slot has same locationId, dont callUpdateCartLines
    let isSameWareHouseForCore = false;
    let isSameWareHouseForParcel = false;
    const updatedCartLines = currentCartLines?.map(cartLine => {
        // Set warehouse ID for DropShip products
        const cartLineProductSpesifications = productspecificationsDetails.find(product => product.ItemId === cartLine.ItemId && product.VariantRecordId === cartLine.ProductId);
        const typeOfProduct = cartLineProductSpesifications?.typeOfProduct?.toLowerCase() || '';

        const smallParcelWarehouseId = cartLineProductSpesifications?.InventLocationId || '';

        if (typeOfProduct === dropShip && !datePicker) {
            if (!cartLine.WarehouseId) {
                cartLine.FulfillmentStoreId = dropShipDefaultWarehouseId;
                cartLine.WarehouseId = dropShipDefaultWarehouseId;
            }
        } else if (typeOfProduct === core|| !typeOfProduct) {
            if (datePicker && !isSameWareHouseForCore && cartLine.WarehouseId === coreProductWarehouseId) {
                isSameWareHouseForCore = true;
            } else {
                cartLine.FulfillmentStoreId = coreProductWarehouseId;
                cartLine.WarehouseId = coreProductWarehouseId;
            }
        } else if (typeOfProduct === smallParcel) {
            if (datePicker && !isSameWareHouseForParcel && cartLine.WarehouseId === smallParcelWarehouseId) {
                isSameWareHouseForParcel = true;
            }
            cartLine.FulfillmentStoreId = smallParcelWarehouseId;
            cartLine.WarehouseId = smallParcelWarehouseId;
        }
        //  Set warehouse ID for Core products
        return cartLine;
    });

    if (_getUpdatedCart) {
        return {
            cartState: cartState,
            updatedCartLines: updatedCartLines
        };

    } else {
        if (!isSameWareHouseForCore || !isSameWareHouseForParcel) {
            await cartOperationDoRetry(() => updateCartLineInternal(cartState?.cart, updatedCartLines ? updatedCartLines : [], actionContext, 'update ware house ID'), cartState).then(() => {
            });
        }
        return undefined;
    }
};

/**
 * VSI Customization - check small parcel out of stock and get the exact location for fedx-ground/small parcel.
 * ticket - 95174 Start.
*/
export const checkSmallParcelOutOfStock = async (zipCode: string, detailsProduct: IProductDetails[], cartLines: CartLine[] | undefined, context: ICoreContext<IGeneric<IAny>>): Promise<IMFIATPInventoryStatusEntity[] | undefined> => {
    const date = new Date();
    const currentDate = date.setDate(date.getDate());
    const tempProducts: { ItemId: string; Quantity: number; VariantRecordId: string }[] = [];
    cartLines?.forEach((item: CartLine) => {
        const parcelDetails = detailsProduct?.find((prod) => item.ProductId === prod.VariantRecordId);
        if (parcelDetails?.typeOfProduct?.toLowerCase() === MFRMProductTypes.smallParcel && !parcelDetails?.outOfStockSmallParcel) {
            tempProducts.push({
                ItemId: item.ItemId ? item.ItemId : parcelDetails.ItemId,
                Quantity: item.Quantity!,
                VariantRecordId: item?.ProductId?.toString()!
            });
        }
    });
    if (tempProducts?.length) {
        const requestBody = {
            InventoryType: DeliveryModes.core,
            StoreId: '',
            Weeks: context.app && context.app.config.weeksPDPforATPCall,
            RequestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
            ZipCode: zipCode, // '77047',
            ItemLines: tempProducts,
            NoOfPriorityDC: context.app.config.NoOfPriorityDC ? context.app.config.NoOfPriorityDC : 0
        };
        const resultData = await atpInventoryDoRetry(() => getInventoryQuantityInternal(requestBody, context, false, 'delivery module for set invent location'));
        const parcelInventoryData: IParcelInventoryDetails[] = [];
        resultData?.result?.ATPInventoryStatusData?.forEach((items: IMFIATPInventoryStatusEntity) => {
            // redirect to cart when any item location is null
            if (!items.Location) {
                window.location.href = `${window.location.origin}/cart`;
            }
            const inventoryDetails = { itemId: items.ItemId, location: items.Location, productId: items.VariantRecordId, quantity: items.Quantity };
            parcelInventoryData.push(inventoryDetails);
        });
        getSetParcelInventoryData('set', parcelInventoryData);
        return resultData?.result?.ATPInventoryStatusData;
    } else {
        return undefined;
    }
};

/**
 * VSI Customization - On submit order check selected Delivery slot is available or not API call.
 * ticket - 85300 Start.
*/
export const _checkATPSlotsAvailable = async (props: ICheckoutViewProps): Promise<IAtpSlotsDetails> => {
    const { app: config, context } = props;
    const itemLinesArray: IMFICartLine[] = [];
    const cookies = new Cookies();
    const zipcode = cookies.get('zip_code');
    const productSpecification = getSetProductData('get');
    let selectedSlotNotExist = true;
    let isNotAvailable = true;
    if (productSpecification && productSpecification.length > 0) {
        productSpecification.map((product: IProductDetails) => {
            if (product.typeOfProduct?.toLowerCase().trim() === MFRMProductTypes.core) {
                const itemLines: IMFICartLine = {
                    ItemId: product.ItemId,
                    Quantity: product.Quantity,
                    VariantRecordId: product.VariantRecordId.toString()
                };
                itemLinesArray.push(itemLines);
            }
        });
        const selectedATPDate = props.data.selectedATPSlotInfo.result?.selectedATPSlotInfo?.Date;

        const date = new Date();
        let currentDate = date.setDate(date.getDate());
        const currHour = date.getHours();
        if (currHour >= 14) {
            currentDate = date.setDate(date.getDate() + 1);
        }
        const requestBody = {
            inventoryType: DeliveryModes.core,
            weeks: config.config.weeksPDPforATPCall,
            storeId: '',
            page: 'pdp',
            requestedDate: dayjs(currentDate).format('MM/DD/YYYY'),
            zipCode: zipcode,
            itemLines: itemLinesArray
        };

        try {
            const mfiInventoryInput = new MfiAtpInventoryInput(
                requestBody.inventoryType,
                requestBody.weeks,
                requestBody.storeId,
                requestBody.page,
                requestBody.requestedDate,
                requestBody.zipCode,
                requestBody.itemLines
            );
            const ATPSlots = await mfiAtpInventoryAction(mfiInventoryInput, context.actionContext);
            if (ATPSlots && ATPSlots.ATPInventoryDynamicData && ATPSlots.ATPInventoryDynamicData.length > 0) {
                ATPSlots.ATPInventoryDynamicData.map((singleDate: IMFIATPInventoryDynamicEntity) => {
                    if (singleDate.AvailableSlots === 'YES') {
                        isNotAvailable = false;
                        if (selectedATPDate && singleDate.Date?.includes(selectedATPDate)) {
                            selectedSlotNotExist = false;
                        }
                    }
                });
            }
        } catch (error) {
            // @ts-ignore
            props.telemetry.warning(error);
            props.telemetry.debug('Unable to mFIATPInventoryDynamicAsync for slots');
        }
        return { selectedSlotNotExist, isNotAvailable };
    } else {
        return { selectedSlotNotExist, isNotAvailable };
    }
};

/** * VSI Customization - Call Full story custom event for check price mismatch details.
 * ticket - 95129 End. */
type PriceCheck = (cartPrice?: number, productPrice?: number) => boolean;
const priceCheck: PriceCheck = (cartPrice, productPrice) => {
    return Math.abs((cartPrice || 0) - (productPrice || 0)) >= 0.01;
};

export const priceMismatchFSEvent = (cartLinesData: CartLine[] | undefined) => {
    const getCartProductPriceArray = getSetCartProductPrice('get');
    const missingTradeIds: number[] = [];
    cartLinesData?.forEach((line) => {
        const getAgreementLines = line.PriceLines?.find((item) => item['@odata.type'].includes('TradeAgreementPriceLine'));
        if (!getAgreementLines) {
            missingTradeIds.push(line.ProductId!);
        }
    });
    const getDiffernce = getCartProductPriceArray?.filter((details: ICartProductPrice) => cartLinesData?.some(itemProduct => details.variantRecordId === itemProduct.ProductId && priceCheck(details.price, itemProduct.Price))
    );
    if (getDiffernce.length > 0 || missingTradeIds.length > 0) {
        void eventFullStoryPriceMismatch('order_health price_mismatch_details', 'on click checkout', getDiffernce, cartLinesData, missingTradeIds);
    }
};

/**
 * VSI Customization - On submit order check selected Delivery slot is available or not API call.
 * ticket - 85300 End.
*/
export const _addDefaultDays = (days: number) => {
    const date = new Date();
    const deliveryDate = date.setDate(date.getDate());
    let deliveryDateFormatted = '';
    deliveryDateFormatted = dayjs(deliveryDate).format('YYYY-MM-DDTHH:mm:ss');
    let newDate;
    while (days > 0) {
        newDate = date.setDate(date.getDate() + 1);
        if (dayjs(newDate).format('d') !== '0' && dayjs(newDate).format('d') !== '6') {
            days--;
        }
    }
    deliveryDateFormatted = dayjs(newDate).format('YYYY-MM-DDTHH:mm:ss');
    return deliveryDateFormatted;
};

/**
 * VSI Customization - create attribute with pre-define objects values.
*/
export const AttributeCreate = (ecomName: string, value: string | undefined) => {
    // Ensure TextValue is always a string or an empty string
    const textValue = value !== undefined ? String(value) : '';
    const preDefine = {
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
        Name: '',
        ExtensionProperties: [],
        TextValue: '',
        TextValueTranslations: []
    };
    const source = { Name: ecomName, TextValue: textValue };
    const returnedObject = Object.assign(preDefine, source);
    return returnedObject;
};

/**
 * VSI Customization - get product attribute.
*/
export const getCheckoutAttributeByName = (attributeValues: AttributeValue[] | undefined, keyValue: string): AttributeValue | undefined => {
    return attributeValues && attributeValues.find(currAttribute => currAttribute.Name && currAttribute.Name?.toLowerCase()?.trim() === keyValue?.toLowerCase()?.trim());
};


/**
 * VSI Customization - get product brand lead time.
*/
export const getBrandLeadTime = (brandList: IBrandListData[], brandName: string | undefined) => {
    return brandList && brandName && brandList?.find(list => list.brandName === brandName)?.brandLeadtime;
};


/**
 * VSI Customization - send Lead ime Or Brand lead time then get the delivery date and request delivery date.
*/
export const generateDeliveryDate = (leadTimeVal: string, brandLeadTime: string) => {
    const timeValue = leadTimeVal || brandLeadTime;
    let date = new Date();
    const daysSpan = timeValue?.split('-');
    let deliveryByMessage = '';
    let deliveryByMessageRange: string = '';
    const currentYear = dayjs().year();
    const deliveryDate = date.setDate(date.getDate());
    daysSpan?.map((item: string, index: number) => {
        if (parseInt(item, 10) > 0) {
            let numberOfDays = parseInt(item, 10);
            let newDate = deliveryDate;
            date = new Date();
            while (numberOfDays > 0) {
                newDate = date.setDate(date.getDate() + 1);
                if (dayjs(newDate).format('d') !== '0' && dayjs(newDate).format('d') !== '6') {
                    numberOfDays--;
                }
            }
            deliveryByMessage = dayjs(newDate).format('MMM D');
            if (deliveryByMessageRange !== '') {
                deliveryByMessageRange = `${deliveryByMessageRange} - ${deliveryByMessage}`;
            } else {
                deliveryByMessageRange = deliveryByMessage;
                newDate = date.setDate(date.getDate() - parseInt(item[0], 10));
            }
        } else {
            let singleDays = parseInt(timeValue, 10);
            let newDate = deliveryDate;
            let flag = true;
            while (singleDays > 0) {
                newDate = date.setDate(date.getDate() + 1);
                if (dayjs(newDate).format('d') !== '0' && dayjs(newDate).format('d') !== '6') {
                    singleDays--;
                } else {
                    flag = false;
                }
            }
            deliveryByMessage = flag && timeValue === '1' ? `${dayjs(date.setDate(date.getDate())).format('MMM D')}` : dayjs(newDate).format('ddd, MMM D');
        }
    });
    deliveryByMessage = deliveryByMessageRange;
    const findDate = deliveryByMessage?.split('-');
    if (findDate?.length > 1) {
        const getDate = dayjs(`${findDate[1]} ${currentYear}`).format('MM/DD/YYYY');
        const fullDate = `${getDate} 12:00:00 AM`;
        const formatDeliveryDate = dayjs(fullDate).format('YYYY-MM-DDTHH:mm:ss');
        return { fullDate, formatDeliveryDate };
    } else {
        const getDate = dayjs(`${date[0]} ${currentYear}`).format('MM/DD/YYYY');
        const fullDate = `${getDate} 12:00:00 AM`;
        const formatDeliveryDate = dayjs(fullDate).format('YYYY-MM-DDTHH:mm:ss');
        return { fullDate, formatDeliveryDate };
    }
};

/**
 * VSI Customization - send the product and get variant type.
*/
const getVariant = (product: SimpleProduct | undefined) => {
    if (!product) {
        return '';
    }
    let dimensionValueCustom = '';
    product.Dimensions?.map((item, index) => {
        if (item.DimensionValue?.Value !== 'Prime') {
            if (index === 0) {
                dimensionValueCustom += item.DimensionValue?.Value!;
            }
            else {
                dimensionValueCustom += ' , ' + `${item.DimensionValue?.Value!}`;
            }
        }
    });
    return dimensionValueCustom;
};

/**
 * VSI Customization - get product details with updated categories values which using for revenue categories.
*/
export const getProductsCategoryPath = async (
    context: ICoreContext<IGeneric<IAny>>,
    products: SimpleProduct[] | undefined,
    productDetailsData: IProductDetails[]
): Promise<IDetailsCategoryWithDetails> => {
    const {
        request: {
            apiSettings: { catalogId }
        },
        app: {
            config: { revenueCategory, otherRevenueDetail, categoryNameBoxSprings, categoryNameAdjustableBase }
        }
    } = context;
    const getProductIdArray: number[] = [];
    products?.forEach((items: SimpleProduct) => getProductIdArray.push(items.RecordId));
    const productsCategories = await getProductsCategoryPathsAction(
        new GetCategoryPathsInput(context.request, catalogId, getProductIdArray),
        context.actionContext
    );
    const revenueCategoryConfig = revenueCategory;
    const newProductDetails = productDetailsData?.map(item => {
        // Find the category information for the current item
        const checkCategory = productsCategories?.find(data => data?.ProductId === item?.VariantRecordId);
        const getSegments = checkCategory?.CategoryPath || [];

        // Combine main and supplementary categories to check
        const categoriesToCheck = [
            ...revenueCategoryConfig,
            { CategoryName: categoryNameAdjustableBase },
            { CategoryName: categoryNameBoxSprings }
        ];

        // Find the first matching category in the available segments
        const foundCategory = categoriesToCheck?.find(categoryItem =>
            getSegments?.some(category => category?.Segments?.some(segment => segment?.CategoryName === categoryItem?.CategoryName))
        );

        item.revenueCategory = foundCategory ? foundCategory?.Name : otherRevenueDetail;
        // If no match found, set categoryName to the last segment's CategoryName
        item.categoryName = foundCategory && foundCategory.CategoryName;

        return item;
    });

    return { productsCategories, newProductDetails };
};

/**
 * VSI Customization - create product detaisl with attribute data.
 * use this utility to get product details and speicfication from local storage
 * this will be called on cart and checkout both. So, updated values will be stored
*/
export const getProuductDetailsHandler = (
    context: ICoreContext<IGeneric<IAny>>,
    cartLine: CartLine,
    attribute: IProductWithAttribute | undefined,
    products: SimpleProduct[] | undefined
) => {
    const {
        actionContext,
        app: {
            config: { brandList, outOfStockProductKey, leadTime, brandBackofficeAttributePdp, placeholderImageName, collection, productType }
        }
    } = context;
    const productTypeInformation = getCheckoutAttributeByName(attribute?.attributeValue, 'shippingInformation')?.TextValue || '';
    const getProduct = products?.find(prod => prod.RecordId === cartLine.ProductId);
    const typeOfProduct = productTypeInformation?.toLowerCase()?.trim() === coreItemShippingType ? MFRMProductTypes.core : productTypeInformation;
    const brandName = parseBrandName(attribute?.attributeValue, brandBackofficeAttributePdp, attribute?.productId) || '';
    const VariantRecordId = attribute?.productId!;
    const categoryName = attribute?.categoryName;
    const name = attribute?.Name || '';
    const ItemId = cartLine.ItemId || '';
    const Quantity = cartLine.Quantity!;
    const outOfStock = getCheckoutAttributeByName(attribute?.attributeValue, outOfStockProductKey)?.TextValue ? true : false;
    const leadTimeVal = getCheckoutAttributeByName(attribute?.attributeValue, leadTime)?.TextValue || '';
    const brandLeadTime = getBrandLeadTime(brandList, brandName) || '';
    const getDeliveryDateDetails = generateDeliveryDate(leadTimeVal, brandLeadTime);
    const brandAndProductName = brandName ? `${brandName} ${name}` : name;
    const productCollection = getCheckoutAttributeByName(attribute?.attributeValue, collection)?.TextValue || '';
    // product type attribute from getAttributeValues and storing in product details and specification
    const productTypeAttribute = getCheckoutAttributeByName(attribute?.attributeValue, productType)?.TextValue || '';
    const variantType = `${getVariant(getProduct)
        ?.toString()
        ?.replace(/,/g, '')}`;
    const imageFallBackUrl = urlFallBackImage(String(placeholderImageName), cartLine.ItemId, actionContext.requestContext) || '';
    const imagePrimary: string = attribute?.PrimaryImageUrl !== undefined ? attribute?.PrimaryImageUrl : '';
    const MasterProductId = getProduct?.MasterProductId;
    const sharedValues = {
        typeOfProduct,
        outOfStock,
        Quantity,
        ItemId,
        VariantRecordId,
        zipCodeError: false,
        leadTimeVal,
        brandLeadTime,
        collection: productCollection,
        outOfStockSmallParcel: false,
        variantType,
        quantityMessage: false,
        brandName,
        name: brandAndProductName,
        productTypeAttribute,
        primaryImageUrl: imagePrimary,
        fallbackImageUrl: imageFallBackUrl
    };
    const productDetailsData = {
        ...sharedValues,
        brandAndProductName,
        categoryName,
        DeliveryDate: getDeliveryDateDetails?.formatDeliveryDate,
        RequestedDeliveryDate: getDeliveryDateDetails?.fullDate
    };
    const productSepcification = {
        ...sharedValues,
        ProducName: name,
        MasterProductId
    };
    const getDetailsProducts = { productDetailsData, productSepcification };
    return getDetailsProducts;
};
/**
 * VSI Customization - to extract params from URL
*/
export const urlParam = (name: string) => {
    // eslint-disable-next-line security/detect-non-literal-regexp
    const results = new RegExp(`[\?&]${name}=([^&#]*)`).exec(window.location.href);
    return results?.[1] || 0;
};

// get USaEpay error details to show fallback error message on checkout
export const getUsaEpayErrorDetails = (errorCode: string, usaEpayErrorMessages: IUsaEpayErrorMessage[]) => {
    return usaEpayErrorMessages?.find((item: IUsaEpayErrorMessage) => item?.errorCode?.includes(errorCode?.trim()) && !item?.exclude) || usaEpayErrorMessages?.find((item: IUsaEpayErrorMessage) => item?.markAsDefault && !item?.exclude);
};

// Following function is used in all checkout module to verify delivery service against each cartline
export const verifyDeliverService = (productId: number, context: ICoreContext<{ [x: string]: any; }>): string => {
    const servicesFromConfigurations = context.request.app.config.deliveryServices;
    const getArrayDeliveryService = getDeliveryService(servicesFromConfigurations);
    const get = getArrayDeliveryService.find((item) => item === productId);
    if (get) {
        return "Delivery";
    } else {
        return '';
    }
};

/**
     * VSI Customization - wrapper for update the exact delivery mode in cart object key.
    */
export const _addDeliveryMode = async (cartLinesData: CartLine[], productAttributes: IProductWithAttribute[], context: ICoreContext<{ [x: string]: any; }>): Promise<CartLine[]> => {
    const {
        app: {
            config: {
                deliveryMessageManagementValues
            }
        }
    } = context;
    const generaCartLines: CartLine[] = [];
    await Promise.all(cartLinesData.map(async (elem: CartLine) => {
        try {
            const attributesList = productAttributes?.find((items) => items.productId === elem.ProductId)?.attributeValue;
            const getMode = attributesList?.find((attribute) => attribute.Name === 'shippingInformation')?.TextValue;
            elem.DeliveryMode = getMode ? getModOfDelivery(getMode, deliveryMessageManagementValues) : verifyDeliverService(elem.ProductId!, context);
            // and response need to be added into final response array
            generaCartLines.push(elem);
        } catch (error) {
            console.log(`error${error}`);
        }
    }));
    return generaCartLines; // return without waiting for process of
};
/**
 * Checkout Page Overlay Modal.
 */
export interface ICheckoutPageOverlayModal {
    modal: INodeProps;
}
// utility to be used with financing offers on checkout to show end date
export const extractMonthAndDate = (dateString: string | undefined): string | undefined => {
    if (!dateString) {
        return undefined;
    }
    // match the date string against a regular expression to extract the month and date
    const match = dateString.match(/(\d{1,2})\/(\d{1,2})\/(\d{2,4})/);
    if (!match) {
        return undefined;
    }
    const month = match[1].padStart(2, '0');
    const date = match[2].padStart(2, '0');
    return `${month}/${date}`;
};

// utility to set address on checkout
export const setAddress = (addUpdateAddress: IMfrmAddress, email?: string, firstName?: string, lastName?: string, emailMeCheckbox?: boolean, phone?: string, address?: string, apt?: string) => {
    set(addUpdateAddress, {
        Email: email ?? '',
        FirstName: firstName ?? '',
        LastName: lastName ?? '',
        EmailMeCheckbox: emailMeCheckbox ?? false,
        Phone: phone,
        Address: address,
        APT: apt
    });
};

// Following function updates tax on checkout page by updating state action GetRecycleFeeCheckoutInput; This function is called everytime after UpdateCartLine API call
export const _updateCheckoutTax = async (
    context: ICoreContext<{
        [x: string]: any;
    }>,
    cartState: ICartState
) => {
    const {
        app: { config }
    } = context;
    const { RecycleFeeAttribute } = config;
    const recycleFeeAttributeValue = RecycleFeeAttribute?.trim().toLowerCase();
    const extentionArray = cartState.cart.ExtensionProperties;
    const fee = extentionArray?.find((item) => item.Key?.trim().toLowerCase() === recycleFeeAttributeValue)?.Value?.DecimalValue!;
    context.actionContext.update(new GetRecycleFeeCheckoutInput(), { recycleFee: Number(fee), tax: Number(cartState.cart?.TaxAmount) });
};


/**
    * VSI Customization - create delivery service re-useable attribute.
*/
export const getServiceItemsAttributes = (isWillCall: boolean , willCallDeliveryDate: string , InventLocation: string | undefined) => {
    const AttributesArray = [];
    const ecomDeliverySetDate = willCallDeliveryDate?.slice(0, -6);
    const dayjsEcomDateDelivery = dayjs(ecomDeliverySetDate).format('YYYY-MM-DDTHH:mm:ss');
    const deliveryDateError: IErrorCaseForDeliveryDate = {
        case: 'ATP Slots Found && selected TimeSLot length ===0 && Product is a service id',
        line: 621,
        value: dayjsEcomDateDelivery
    };
    const deliveryDateErrorWillCall: IErrorCaseForDeliveryDate = {
        case: 'Slots found and else part and is a service product',
        line: 1585,
        value: dayjsEcomDateDelivery
    };
    const ecomDeliveryDate = AttributeCreate(
        'Ecom_DeliveryDate',
        dayjsEcomDateDelivery ? dayjsEcomDateDelivery : JSON.stringify(deliveryDateError)
    );
    const ecomDeliveryDatewillCall = AttributeCreate(
        'Ecom_DeliveryDate',
        dayjsEcomDateDelivery ? dayjsEcomDateDelivery : JSON.stringify(deliveryDateErrorWillCall)
    );
    const ecomDSDeliveryScheduleStatus = AttributeCreate('Ecom_DSDeliveryScheduleStatus' , 'Open');
    const ecomInventLocationId = AttributeCreate( 'Ecom_InventLocationId', InventLocation);
    if(isWillCall) {
        AttributesArray.push(ecomDeliveryDatewillCall, ecomDSDeliveryScheduleStatus, ecomInventLocationId);
    } else {
        AttributesArray.push(ecomDeliveryDate, ecomDSDeliveryScheduleStatus, ecomInventLocationId);
    }
    return AttributesArray;
};

/**
    * VSI Customization - create core re-useable attribute.
*/
export const getCoreProductAttributes = (isWillCall: boolean, willCallDeliveryDate: string , InventLocation: string | undefined) => {
    // core items attributes
    const AttributesArray = [];
    const ecomDeliverySetDate = willCallDeliveryDate?.slice(0, -6);
    const dayjsEcomDateDelivery = dayjs(ecomDeliverySetDate).format('YYYY-MM-DDTHH:mm:ss');
    const deliveryDateError: IErrorCaseForDeliveryDate = {
        case: 'ATP Slots Found && selected time slot selectedTimeSlot length = 0 && Delivery Item in a will call case',
        line: 659,
        value: dayjsEcomDateDelivery
    };
    const ecomDeliveryDate = AttributeCreate(
        'Ecom_DeliveryDate',
        dayjsEcomDateDelivery ? dayjsEcomDateDelivery : JSON.stringify(deliveryDateError)
    );
    const deliveryDateErrorWillCall: IErrorCaseForDeliveryDate = {
        case: 'Slots found and else part and is a Core product',
        line: 1623,
        value: dayjsEcomDateDelivery
    };
    const ecomDeliveryDateWillCall = AttributeCreate(
        'Ecom_DeliveryDate',
        dayjsEcomDateDelivery ? dayjsEcomDateDelivery : JSON.stringify(deliveryDateErrorWillCall)
    );
    const ecomDSDeliveryScheduleStatus = AttributeCreate('Ecom_DSDeliveryScheduleStatus' , 'Open');
    const ecomInventLocationId = AttributeCreate( 'Ecom_InventLocationId', InventLocation);
    if(isWillCall) {
        AttributesArray.push(ecomDeliveryDateWillCall, ecomDSDeliveryScheduleStatus, ecomInventLocationId);
    } else {
        AttributesArray.push(ecomDeliveryDate, ecomDSDeliveryScheduleStatus, ecomInventLocationId);
    }
    return AttributesArray;
};

/**
    * VSI Customization - create parcel re-useable attribute.
*/
export const getParcelProductAttributes = (productDetails: IProductDetails, isWillCall: boolean, deliveryMsg: { shippingInformation: string; MOD: string; }[] | undefined, daysParcel: number | undefined ) => {
    // parcel items attributes
    const AttributesArray = [];
    const defaultDaysParcel = daysParcel && daysParcel > 0 && _addDefaultDays(daysParcel);
    const getModDelivery = getModOfDelivery('Parcel', deliveryMsg!);
    const ecomDeliveryServiceMode = AttributeCreate('Ecom_DeliveryService', getModDelivery);
    const deliveryDateError: IErrorCaseForDeliveryDate = {
        case: 'ATP Slots Found && selected time slot length =0 && Parcel Item',
        line: 724,
        value: productDetails?.DeliveryDate
    };
    const ecomDeliveryDate = AttributeCreate(
        'Ecom_DeliveryDate',
        productDetails?.DeliveryDate
            ? productDetails?.DeliveryDate
            : defaultDaysParcel
            ? defaultDaysParcel
            : JSON.stringify(deliveryDateError)
    );
    const deliveryDateErrorWillCall: IErrorCaseForDeliveryDate = {
        case: 'Slots found and else part and is a parcel product',
        line: 1711,
        value: productDetails?.DeliveryDate
    };
    const ecomDeliveryDateWillCall = AttributeCreate(
        'Ecom_DeliveryDate',
        productDetails?.DeliveryDate
            ? productDetails?.DeliveryDate
            : defaultDaysParcel
            ? defaultDaysParcel
            : JSON.stringify(deliveryDateErrorWillCall)
    );
    if(isWillCall) {
        AttributesArray.push(ecomDeliveryServiceMode, ecomDeliveryDateWillCall );
    } else {
        AttributesArray.push(ecomDeliveryServiceMode, ecomDeliveryDate );
    }

    return AttributesArray;
};


/**
    * VSI Customization - create drop ship re-useable attribute.
*/
 export const getDropShipProductAttributes = (productDetails: IProductDetails, isWillCall: boolean, deliveryMsg: { shippingInformation: string; MOD: string; }[] | undefined, daysDropShip: string | undefined ) => {
        // drop ship items attributes
        const AttributesArray = [];
        const getModDelivery = getModOfDelivery(MFRMProductTypes.dropShip, deliveryMsg!);
        const ecomDeliveryServiceMode = AttributeCreate('Ecom_DeliveryService', getModDelivery);
        const deliveryDateError: IErrorCaseForDeliveryDate = {
            case: 'ATP Slots Found && selected time slot && length = 0 && Drop Ship Item',
            line: 771,
            value: productDetails?.DeliveryDate
        };
        const deliveryDateErrorWillCall: IErrorCaseForDeliveryDate = {
            case: 'Slots found and else part and is a dropship product',
            line: 1766,
            value: productDetails?.DeliveryDate
        };
        const ecomDeliveryDate = AttributeCreate(
            'Ecom_DeliveryDate',
            productDetails?.DeliveryDate
                ? productDetails?.DeliveryDate
                : daysDropShip
                ? daysDropShip
                : JSON.stringify(deliveryDateError)
        );
        const ecomDeliveryDateWillCall = AttributeCreate(
            'Ecom_DeliveryDate',
            productDetails?.DeliveryDate
                ? productDetails?.DeliveryDate
                : daysDropShip
                ? daysDropShip
                : JSON.stringify(deliveryDateErrorWillCall)
        );
        const ecomDSShippingMessage = AttributeCreate(
            'Ecom_ShippingMessage',
            `Arrives ${getDeliveryMessageForProduct(productDetails?.VariantRecordId)}`
        );
        if(isWillCall) {
            AttributesArray.push(
                ecomDeliveryServiceMode,
                ecomDSShippingMessage,
                ecomDeliveryDateWillCall
            );
        } else {
            AttributesArray.push(
                ecomDeliveryServiceMode,
                ecomDSShippingMessage,
                ecomDeliveryDate
            );
        }
        return AttributesArray;
    };
// Following function detects if browser is Safari or not
export const isSafari = (): boolean => {
    return !!(navigator.vendor && navigator.vendor.indexOf('Apple') > -1);
};

// express skeleton check
export const handleExpressSkeletonCheck = (actionContext: IActionContext, data: IMfrmCheckoutShippingAddressData | IMfrmCheckoutDeliveryOptionsData) => {
    actionContext.update(new CheckoutSkeletonInput(), {
        showPaymentSkeleton: data.showSkeleton.result?.showPaymentSkeleton,
        expressSkeleton: data.showSkeleton.result?.expressSkeleton,
        expressPhoneNotavailable: data.showSkeleton.result?.expressPhoneNotavailable,
        expressLoaderRunning: false,
        showDeliverySkeleton: data.showSkeleton.result?.showDeliverySkeleton,
        showCustomerInfoSkeleton: data.showSkeleton.result?.showCustomerInfoSkeleton
    });
};

export const hasCoreProduct = (products?: IProductDetails[]) => {
    const productSpecificationsCookieData = products ? products : getSetProductData('get');
    if (productSpecificationsCookieData && productSpecificationsCookieData.length) {
        for (const psp of productSpecificationsCookieData) {
            if (psp.typeOfProduct.toLowerCase().trim() === MFRMProductTypes.core) {
                return true;
            }
        }
    }
    return false;
};

// When checkout data action's value is not available e.g. in another data action (getCardPaymentAcceptPoint); check if this is express checkout using url like following
export const isExpressCheckout = (actionContext: IActionContext) => {
    const { requestContext: { url, app: { config: { expressApplePayCheckouturl, expressCheckouturl } } } } = actionContext;
    const checkoutURl = url.requestUrl.pathname?.split('/')[1]?.toLowerCase()?.trim();
    // Check if it's express checkout page
    return !!(checkoutURl === expressApplePayCheckouturl || checkoutURl === expressCheckouturl);
};

// Check if it's cart page or express checkout page to avoid unnecassary API calls on normal checkout flow
export const isExpressPage = (actionContext: IActionContext) => {
    const { requestContext: { url, app: { config: { expressApplePayCheckouturl, expressCheckouturl } } } } = actionContext;
    const pageURL = url.requestUrl.pathname?.split('/')[1]?.toLowerCase()?.trim();
    // Check if it's express checkout page or cart page
    return !!(pageURL === expressApplePayCheckouturl || pageURL === expressCheckouturl || pageURL === 'cart');
};

/**
 * Retrieves and constructs a shipping address based on cookies and shipping info data.
 */
export const getAddressFromCookies = (context: ICoreContext, zipCodeValue?: string): IShippingAddress => {
    const cookies = new Cookies();
    const extraInfo = cookies.get('shipping_extra_info');
    const bCookie: IBasketCookie =  getSetBasketCookie('get', context);
    const zipCode =  zipCodeValue || cookies.get('zip_code');
    const firstName = bCookie?.firstName || '';
    const lastName = bCookie?.lastName || '';
    const city = extraInfo?.city || '';
    const state = extraInfo?.state || '';
    const address1 = bCookie?.address1 || '';
    const address2 = bCookie?.address2 || '';
    const country = extraInfo?.ThreeLetterISORegionName || '';

    return {
        Name: `${firstName}##${lastName}`,
        AddressTypeValue: 6,
        City: city,
        State: state,
        Street: address2 ? `${address1}##${address2}` : address1,
        ZipCode: zipCode,
        ThreeLetterISORegionName: country
    };
};

export const getProgressiveTotal = (
    cartState: ICartState | undefined,
    deliveryOptions: IDeliveryProps[],
    deliveryStates: string[]
): number => {
    const getArrayDeliveryService = getDeliveryService(deliveryOptions);
    const selectedState = getCookie('cInfo_State') || '';
    if (!cartState) {
        return 0; // Handle the case where cartState is undefined.
    }
    const { cart } = cartState;
    const { ShippingAddress } = cart;
    const state = ShippingAddress?.State || selectedState;
    let filterCartLine: CartLine[] | undefined = cartState?.cart.CartLines;

    // Check if delivery fee is not allowed in the current state
    const checkNotAllowDeliveryFee = deliveryStates?.includes(state);

    // If not allowed, filter out CartLines that have specific ProductIds
    if (checkNotAllowDeliveryFee) {
        filterCartLine = filterCartLine?.filter((items) => {
        return !getArrayDeliveryService?.some((service) => service === items.ProductId);
        });
    }
    let netAmountTotal = 0;
    // Calculate netAmountTotal by iterating through the filtered CartLines
    filterCartLine?.forEach(item => {
        netAmountTotal += item.ExtendedPrice || 0;
    });
    return Number(netAmountTotal?.toFixed(2));
};

// Check if it's payment step or not
export const isPaymentStep = (context: ICoreContext<{ [x: string]: any; }>) => {
    // Read cookies from server for initial render to get current checkout step
    const contextCookies = context.request.cookies;
    const serverCookies = new Cookies(contextCookies);
    const hasCoreProduct = serverCookies.get('has-core-product');
    const checkoutCurrentStep = Number(getSetCheckoutCurrentStep('get'));
    return (hasCoreProduct === 'true' && checkoutCurrentStep === 2) || (hasCoreProduct === 'false' && checkoutCurrentStep === 1);
};

export const deleteProgApplicationTransaction = async (progressiveLeaseId: string | undefined, ctx: IActionContext) => {
    const { progressiveTenderType, progressiveTransactionType } = ctx.requestContext.app.config;
    // Create the input for data-action
    const actionInput = {
        ApplicationId: Number(progressiveLeaseId),
        TenderType: progressiveTenderType,
        TransactionType: progressiveTransactionType
    };
    // Run and await the result of the data action to delete the transaction
    await ProgressiveDeleteTransactionAction(new ProgressiveDeleteTransactionInput(actionInput), ctx).then().catch();
};

export const utagLinkProgressiveApply = () => {
    void callUtagLink({
        event: "Payment-Progressive-Apply",
        event_category: "Financing",
        event_action: "Payment page Apply Now button click",
        event_label:"Progressive - Apply Now",
        event_noninteraction: "false",
        event_type: "lto_button"
    });
};

/** Following function format the phone to (xxx) xxx-xxxx format as in API, it stores like xxx-xxx-xxxx */
export const _formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = ''.concat(phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return '('.concat(match[1]).concat(') ').concat(match[2]).concat('-').concat(match[3]);
    }
    return phoneNumberString;
};

/** Following function changes any phone string format (xxx) xxx-xxx to xxx-xxx-xxxx */
export const changePhoneFormatForAPI = (phone: string) => {
    return phone?.replace('(','').replace(')','').split((/[\s-]+/)).join('-');
};

/** Following function changes phone format from xxx-xxx-xxxx to (xxx) xxx-xxx to be shown on confirmation page */
export const changePhoneFormatForDisplay = (phone: string) => {
    const phoneArray = phone.split('-');
    if (phoneArray.length && phoneArray.length === 3) {
        return `(${phoneArray[0]}) ${phoneArray[1]}-${phoneArray[2]}`;
    }
    return phone;
};

/** Following function changes any phone string format from given Address */
export const changeAddressPhoneFormatForAPI = (address: Address) => {
    const phone = address.Phone;
    if (phone) {
        const updatedPhone = phone && changePhoneFormatForAPI(phone); // Just updated phone format from (xxx) xxx-xxxx to xxx-xxx-xxxx
        address.Phone = updatedPhone;
    }
    return address;
};

/** Following function changes phone format from a given Address to be displayed on the checkout page */
export const changeAddressPhoneFormatForDisplay = (address: Address) => {
    const phone = address.Phone;
    if (phone) {
        const updatedPhone = phone && changePhoneFormatForDisplay(phone); // Just updated phone format from xxx-xxx-xxxx to (xxx) xxx-xxxx
        address.Phone = updatedPhone;
    }
    return address;
};

/** Following function return if it's an usaePay order i.e. credit card or applepay express order */
export const isUsaEpayOrder = (): boolean => {
    const selectedPaymentType = getSetPaymentRadioName('get')?.toLowerCase().trim();
    const isUSAePay = selectedPaymentType === 'credit card' || selectedPaymentType === 'applepay';
    const expressType = getSetExpressType('get')?.toLowerCase().trim();
    const isApplePayExpress = expressType === 'applepay';
    return isUSAePay || isApplePayExpress;
};

export const removesSpecialCharacterFromString = (str: string) => {
    return str.replace(/[^a-zA-Z0-9 ]/g, '').replace(/\s/g, '' );
};

// Function to filter slot object based on selection criteria
export const filterAtpSlots = (atplSlots: IMFIATPInventoryEntity[]) => {
    const getSelectedSlotZone = getSetSelectedDeliverySlotZone('get');
    const getTimeSlot = getSetTimeSlot('get');
    const [getStartTime, getEndTime] = getTimeSlot?.split(' - ');

    // Filter by Zone and Available
    let atpResult = atplSlots?.find(item => item?.Zone === getSelectedSlotZone && item?.Available === "YES");

    // If not found, filter by StartTime and EndTime
    if (!atpResult) {
        atpResult = atplSlots?.find(item => item?.Available === "YES" && item?.StartTime === getStartTime && item?.EndTime === getEndTime);
    }

    // If still not found, assign the first slot
    if (!atpResult) {
        atpResult = atplSlots[0];
    }

    return atpResult;
};